import {SET_NOTIFICATIONS, SET_TOPIC_NOTIFICATIONS} from '../actions/types';

const initialState = {
  allNotifications: [{
    "id": "184bf71e-2572-4c96-b035-7d48eea65018",
    "notification_type_id": 1,
    "notification_type": "Notification Type",
    "notification_by": "Hashim Lokasher",
    "message": "Test Notification",
    "timestamp": "2021-04-28T09:25:03.000000Z",
    "notification_time": "1 day ago",
    "read_at": "",
    "payload": {
      "url": "http://schoolroomhelp.com/"
    }
  }],
  unreadNotifications: [{
    "id": "184bf71e-2572-4c96-b035-7d48eea65019",
    "notification_type_id": 1,
    "notification_type": "Notification Type",
    "notification_by": "Zachary Groves",
    "message": "Your address has been updated.",
    "timestamp": "2021-04-28T09:25:03.000000Z",
    "notification_time": "1 day ago",
    "read_at": "",
    "payload": {
      "url": "http://schoolroomhelp.com/"
    }
  }], topicNotifications: {
    "questions": {
      "count": 1,
      "data": [
        {
          "id": "d9738afe-f4cd-4383-9f9a-060ff0c096b2",
          "notification_type": "Notification Type",
          "notification_by": "Hashim Lokasher",
          "message": "Your address has been updated.",
          "notification_time": "9 minutes ago",
          "payload": {
            "url": "http://schoolroomhelp.com/"
          }
        },
      ]
    },
    "answers": {
      "count": 1,
      "data": [
        {
          "id": "d9738afe-f4cd-4383-9f9a-060ff0c096b2",
          "notification_type": "Notification Type",
          "notification_by": "Hashim Lokasher",
          "message": "Your address has been updated.",
          "notification_time": "9 minutes ago",
          "payload": {
            "url": "http://schoolroomhelp.com/"
          }
        },
      ]
    },
    "comments": {
      "count": 1,
      "data": [
        {
          "id": "d9738afe-f4cd-4383-9f9a-060ff0c096b2",
          "notification_type": "Notification Type",
          "notification_by": "Hashim Lokasher",
          "message": "Your address has been updated.",
          "notification_time": "9 minutes ago",
          "payload": {
            "url": "http://schoolroomhelp.com/"
          }
        },
      ]
    }
  }
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {...state, allNotifications: action.payload}

    case SET_TOPIC_NOTIFICATIONS:
      return {...state, topicNotifications: action.payload}

    default:
      return state
  }
}

export default notificationReducer
