import mock from "../mock"

export const searchResult = [
  {
    id: 1,
    target: "AnalyticsDashboard",
    title: "Dashboard",
    link: "/",
    icon: "Home",
    starred: false
  },
  {
    id: 2,
    target: "my_answers",
    title: "My Answers",
    link: "/tutor/answers",
    icon: "Copy",
    starred: false
  },
  {
    id: 3,
    target: "my_topics",
    title: "My Topics",
    link: "/tutor/topics",
    icon: "Server",
    starred: false
  },
  {
    id: 4,
    target: "invite_students",
    title: "Invite Students",
    link: "/tutor/invitations",
    icon: "Send",
    starred: false
  },
  {
    id: 5,
    target: "notifications",
    title: "Notifications",
    link: "/tutor/notifications",
    icon: "Bell",
    starred: false
  },
  {
    id: 6,
    target: "my_subjects",
    title: "My Subjects",
    link: "/tutor/subjects",
    icon: "Book",
    starred: false
  },
  {
    id: 7,
    target: "my_education",
    title: "My Education",
    link: "/tutor/education",
    icon: "Book",
    starred: false
  },
  {
    id: 8,
    target: "my_experience",
    title: "My Experience",
    link: "/tutor/experience",
    icon: "Clock",
    starred: false
  }

]

mock.onGet("/api/search/bookmarks/data").reply(200, {
  searchResult
})

mock.onPost("/api/update/bookmarks").reply(request => {
  const bookmarkToUpdate = JSON.parse(request.data).obj

  searchResult.filter(i => {
    if (i.id === bookmarkToUpdate.id) {
      return (i.starred = !bookmarkToUpdate.starred)
    } else {
      return null
    }
  })
  return [200]
})
