import mock from "../mock"
let data = {
  events: [
    {
      id: 1,
      title: "My Event",
      start: new Date(2021, 5, 17),
      end: new Date(2021, 5, 17),
      label: "business",
      allDay: true,
      selectable: true
    },{
      id: 2,
      title: "My Event",
      start: new Date(2021, 5, 17),
      end: new Date(2021, 5, 17),
      label: "business",
      allDay: true,
      selectable: true
    }, {
      id: 3,
      title: "My Event",
      start: new Date(2021, 5, 23),
      end: new Date(2021, 5, 23),
      label: "business",
      allDay: true,
      selectable: true
    }, {
      id: 3,
      title: "My Event",
      start: new Date(2021, 5, 24),
      end: new Date(2021, 5, 24),
      label: "business",
      allDay: true,
      selectable: true
    }
  ]
}

// GET : Calendar Events
mock.onGet("/api/apps/calendar/events").reply(() => {
  return [200, data.events]
})
