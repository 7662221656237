module.exports = {
  chat_url:'https://chat.schoolroomhelp.net',
  base_url: 'https://schoolroomhelp.net/api',
  home_url:'https://schoolroomhelp.net',
  login_url: 'https://auth.schoolroomhelp.net/pro',
  logout_url: 'https://auth.schoolroomhelp.net/authorize?logout=true&role=professor',



  //base_url: 'https://schoolroomhelp.20thfloor.us/api',
  //home_url:'https://schoolroomhelp.20thfloor.us',
  //login_url: 'https://auth.20thfloor.us/pro',
  //logout_url: 'https://auth.20thfloor.us/authorize?logout=true&role=professor',
  //chat_url:'https://chat.20thfloor.com',

  //base_url: "https://schoolroomhelp.com/api",
  //home_url: "https://schoolroomhelp.com",
  //login_url: "https://auth.schoolroomhelp.com/pro",
  //logout_url: "https://auth.schoolroomhelp.com/authorize?logout=true&role=professor",
  //chat_url: 'https://chat.schoolroomhelp.com',


  // base_url: 'http://localhost:8000/api',
  // home_url:'http://localhost:8000',
  // login_url: 'http://localhost:3000',
  // logout_url: 'http://localhost:3000/authorize?logout=true&role=professor',
};
