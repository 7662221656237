const SET_USER = 'SET_USER';
const SET_PHOTO = 'SET_PHOTO';
const ALTER_USER = 'ALTER_USER';
const ALTER_JUST_USER = 'ALTER_JUST_USER';
const SET_TOKEN = 'SET_TOKEN';
const SET_BOTTOM_TAB_BAR = 'SET_BOTTOM_TAB_BAR';
const SET_BOTTOM_TAB_BAR_INDEX = 'SET_BOTTOM_TAB_BAR_INDEX';
const SET_LANGUAGE = 'SET_LANGUAGE';
const SET_FEEDS = 'SET_FEEDS';

const GET_NOTIFICATIONS='GET_NOTIFICATIONS';
const SET_NOTIFICATIONS='SET_NOTIFICATIONS';
const SET_TOPIC_NOTIFICATIONS='SET_TOPIC_NOTIFICATIONS';


export {
  SET_USER,
  SET_PHOTO,
  ALTER_USER,
  ALTER_JUST_USER,
  SET_TOKEN,
  SET_BOTTOM_TAB_BAR,
  SET_BOTTOM_TAB_BAR_INDEX,
  SET_LANGUAGE,
  SET_FEEDS,
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_TOPIC_NOTIFICATIONS
};
